import hogwarts from './hogwarts.jpg'
import hogwarts1 from './hogwarts1.jpg'
import hogwarts2 from './hogwarts2.jpg'
import hogwarts3 from './hogwarts3.jpg'
import hogwarts4 from './hogwarts4.jpg'
import hogwarts5 from './hogwarts5.jpg'
import hogwarts6 from './hogwarts6.jpg'
import hogwarts7 from './hogwarts7.jpg'
import hogwarts8 from './hogwarts8.jpg'
import hogwarts9 from './hogwarts9.jpg'
import hogwarts10 from './hogwarts10.jpg'
import hogwarts11 from './hogwarts11.jpg'
import hogwarts12 from './hogwarts12.jpg'

export const bgImages = [
    hogwarts,
    hogwarts1,
    hogwarts2,
    hogwarts3,
    hogwarts4,
    hogwarts5,
    hogwarts6,
    hogwarts7,
    hogwarts8,
    hogwarts9,
    hogwarts10,
    hogwarts11,
    hogwarts12
];