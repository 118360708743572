import React, { useContext } from "react"
import styled, { keyframes } from "styled-components"
import { quoteContext } from '../contexts/QuoteContext';
import "./animation.css";

export default function TextAnimation() {
    const {value} = useContext(quoteContext)
    return <Wrapper><p className="quote">"{value[0]}"  -  {value[1]}</p></Wrapper>
}

const animation = keyframes`
  0% { opacity: 0;}
  100% { opacity: 1;}
`

const Wrapper = styled.h1`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 10s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
`