export const quotes = [
    ["Where your treasure is, there will your heart be also.", "Albus Dumbledore"],
    ["Not my daughter, you bitch!", "Molly Weasley"],
    ["You think the dead we loved ever truly leave us? You think that we don't recall them more clearly than ever in times of great trouble? Your father is alive in you, Harry, and shows himself plainly when you have need of him.", "Albus Dumbledore"],
    ["Happiness can be found, even in the darkest of times, if one only remembers to turn on the light.", "Albus Dumbledore"],
    ["Indeed, your failure to understand that there are things much worse than death has always been your greatest weakness.", "Albus Dumbledore"],
    ["I say there are spots that don't come off… Spots that never come off, d'you know what I mean.", "Mad-Eye Moody"],
    ["Nothing like a nighttime stroll to give you ideas.", "Mad-Eye Moody"],
    ["If you want to know what a man's like, take a good look at how he treats his inferiors, not his equals.", "Sirius Black"],
    ["I sometimes find, and I am sure you know the feeling, that I simply have too many thoughts and memories crammed into my mind…. At these times… I use the Pensieve. One simply siphons the excess thoughts from one's mind, pours them into the basin, and examines them at one's leisure.", "Albus Dumbledore"],
    ["Curiosity is not a sin… But we should exercise caution with our curiosity… yes, indeed.", "Albus Dumbledore"],
    ["In dreams, we enter a world that's entirely our own.", "Albus Dumbledore"],
    ["To the well-organized mind, death is but the next great adventure.", "Albus Dumbledore"],
    ["We must try not to sink beneath our anguish, Harry, but battle on.", "Albus Dumbledore"],
    ["It is the unknown we fear when we look upon death and darkness, nothing more.", "Albus Dumbledore"],
    ["Ah, music. A magic beyond all we do here!", "Albus Dumbledore"],
    ["Indifference and neglect often do much more damage than outright dislike …We wizards have mistreated and abused our fellows for too long, and we are now reaping our reward.", "Albus Dumbledore"],
    ["People find it far easier to forgive others for being wrong than being right.", "Albus Dumbledore"],
    ["It does not do to dwell on dreams and forget to live.", "Albus Dumbledore"],
    ["Age is foolish and forgetful when it underestimates youth.", "Albus Dumbledore"],
    ["When you have seen as much of life as I have, you will not underestimate the power of obsessive love.", "Albus Dumbledore"],
    ["It takes a great deal of bravery to stand up to our enemies, but just as much to stand up to our friends.", "Albus Dumbledore"],
    ["Fear of a name increases fear of the thing itself.", "Albus Dumbledore"],
    ["You sort of start thinking anything's possible if you've got enough nerve.", "Ginny Weasley"],
    ["Hearing voices no one else can hear isn't a good sign, even in the wizarding world.", "Ron Weasley"],
    ["It is our choices… that show what we truly are, far more than our abilities.", "Albus Dumbledore"],
    ["Never trust anything that can think for itself if you can't see where it keeps its brain.", "Arthur Weasley"],
    ["As much money and life as you could want! The two things most human beings would choose above all – the trouble is, humans do have a knack of choosing precisely those things that are worst for them.", "Albus Dumbledore"],
    ["There is no good or evil: only power and those too weak to seek it.", "Quirinus Quirrell"],
    ["The mind is not a book, to be opened at will and examined at leisure. Thoughts are not etched on the inside of skulls, to be perused by an invader. The mind is a complex and many-layered thing.", "Severus Snape"],
    ["Indifference and neglect often do much more damage than outright dislike.", "Albus Dumbledore"],
    ["The truth is a beautiful and terrible thing, and should therefore be treated with caution.", "Albus Dumbledore"],
    ["Youth cannot know how age thinks and feels. But old men are guilty if they forget what it was to be young.", "Albus Dumbledore"],
    ["He chose the boy he thought most likely to be a danger to him … and notice this, Harry. He chose, not the pureblood (which according to his creed, is the only kind of wizard worth being or knowing), but the half-blood, like himself. He saw himself in you before he had ever seen you, and in marking you with that scar, he did not kill you, as he intended, but gave you powers, and a future, which have fitted you to escape him not once, but four times so far.", "Albus Dumbledore"],
    ["Numbing the pain for a while will make it worse when you finally feel it.", "Albus Dumbledore"],
    ["Time is making fools of us again.", "Albus Dumbledore"],
    ["It takes a great deal of bravery to stand up to our enemies, but just as much to stand up to our friends.", "Albus Dumbledore"],
    ["Fear of a name increases fear of the thing itself.", "Albus Dumbledore"],
    ["I hope you're pleased with yourselves. We could all have been killed - or worse, expelled.", "Hermione Granger"]
    ["Your mother died to save you. If there is one thing Voldemort cannot understand, it is love. Love as powerful as your mother's for you leaves it's own mark.", "Albus Dumbledore"],
    ["The wand chooses the wizard.", "Garrick Ollivander"],
    ["Funny way to get to a wizards' school, the train. Magic carpets all got punctures, have they?", "Uncle Vernon"],
    ["Troll — in the dungeons — thought you ought to know.", "Professor Quirrell"],
    ["Training for the ballet, Potter?", "Draco Malfoy"],
    ["Of all the trees we could've hit, we had to get one that hits back.", "Ron Weasley"],
    ["You will also find that help will always be given at Hogwarts to those who ask for it.", "Albus Dumbledore"],
    ["Honestly, if you were any slower, you'd be going backward.", "Draco Malfoy"],
    ["Dobby has never been asked to sit down by a wizard — like an equal.", "Dobby"],
    ["I'll be in my bedroom, making no noise and pretending I'm not there.", "Harry Potter"],
    ["Don't let the muggles get you down.", "Ron Weasley"],
    ["Mr. Moony presents his compliments to Professor Snape, and begs him to keep his abnormally large nose out of other people's business.", "Mr.Moony/The Marauder's Map"],
    ["I solemnly swear that I am up to no good.", "George Weasley"],
    ["I want to commit the murder I was imprisoned for.", "Sirius Black"],
    ["Fawkes is a phoenix, Harry. Phoenixes burst into flame when it is time for them to die and are reborn from the ashes.", "Albus Dumbledore"],
    ["Are you insane? Of course I want to leave the Dursleys! Have you got a house? When can I move in?", "Harry Potter"],
    ["Mischief Managed!", "Harry Potter"],
    ["Why, dear boy, we don't send wizards to Azkaban just for blowing up their aunts.", "Cornelius Fudge"],
    ["Your devotion is nothing more than cowardice. You would not be here if you had anywhere else to go.", "Voldemort"],
    ["Just because it's taken you three years to notice, Ron, doesn't mean no one else has spotted I'm a girl!", "Hermione Granger"],
    ["Anyone can speak Troll. All you have to do is point and grunt.", "Fred Weasley"],
    ["Twitchy little ferret, aren't you, Malfoy?", "Hermione Granger"],
    ["Just because you have the emotional range of a teaspoon doesn't mean we all have.", "Hermione Granger"],
    ["Wit beyond measure is man's greatest treasure.", "Luna Lovegood"],
    ["Don't worry. You're just as sane as I am.", "Luna Lovegood"],
    ["Yeah, Quirrell was a great teacher. There was just that minor drawback of him having Lord Voldemort sticking out of the back of his head!", "Harry Potter"],
    ["Youth can not know how age thinks and feels. But old men are guilty if they forget what it was to be young.", "Albus Dumbledore"],
    ["You should write a book, translating mad things girls do so boys can understand them.", "Ron Weasley"],
    ["The mind is not a book, to be opened at will and examined at leisure. Thoughts are not etched on the inside of skulls, to be perused by an invader.", "Severus Snape"],
    ["Ron - you are the most insensitive wart I have ever had the misfortune to meet.", "Hermione Granger"],
    ["Once again, you show all the sensitivity of a blunt axe.", "Nearly Headless Nick"],
    ["Dumbledore says people find it far easier to forgive others for being wrong than being right.", "Hermione Granger"],
    ["When you have seen as much of life as I have, you will not underestimate the power of obsessive love.", "Horace Slughorn"],
    ["You need your friends, Harry.", "Albus Dumbledore"],
    ["He can run faster than Severus Snape confronted with shampoo.", "Fred Weasley"],
    ["I always said he was mental. Brilliant and everything, but cracked.", "Ron Weasley"],
    ["Always", "Severus Snape"],
    ["Of course it is happening inside your head, Harry, but why on earth should that mean that it is not real?", "Albus Dumbledore"],
    ["Ron, you know full well Harry and I were brought up by Muggles!", "Hermione Granger"],
    ["It matters not what someone is born, but what they grow to be.", "Albus Dumbledore"],
    ["The consequences of our actions are always so complicated, so diverse, that predicting the future is a very difficult business indeed.", "Albus Dumbledore"],
    ["Do not pity the dead, Harry. Pity the living, and, above all those who live without love.","Albus Dumbledore"],
    ["Snape's patronus was a doe, the same as my mother's because he loved her for nearly all of his life, from when they were children.","Harry Potter"],
    ["Albus Severus, you were named for two headmasters of Hogwarts. One of them was a Slytherin and he was probably the bravest man I ever knew.","Harry Potter"],
    ["Until the very end","James Potter"],
    ["It is impossible to manufacture or imitate love","Horace Slughorn"],
    ["Books! And cleverness! There are more important things — friendship and bravery.","Hermione Granger"],
    ["We're with you whatever happens.","Ron Weasley"],
    ["No good sittin' worryin' abou' it. What's comin' will come, an' we'll meet it when it does.","Rubeus Hagrid"],
    ["Every human life is worth the same, and worth saving.","Kingsley Shacklebolt"],
    ["Sadly, accidental rudeness occurs alarmingly often. Best to say nothing at all, my dear man.","Albus Dumbledore"],
    ["There's things more importan' than keepin' a job.","Rubeus Hagrid"],
    ["We've been hearing explosions out of their room for ages, but we never thought they were actually making things. We thought they just liked the noise.","Ginny Weasley"],
    ["You know, Minister, I disagree with Dumbledore on many counts... but you cannot deny he's got style...","Phineas Nigellus"],
    ["Dobby is free","Dobby"],
    ["Accio Brain!","Ron Weasley"],
    ["I think we've outgrown full-time education... Time to test our talents in the real world, d'you reckon?","Fred Weasley"],
    ["I mean, it's sort of exciting, isn't it, breaking the rules?","Hermione Granger"],
    ["We've all got both light and dark inside us. What matters is the part we choose to act on. That's who we really are.","Sirius Black"],
    ["It is the quality of one's convictions that determines success, not the number of followers.","Remus Lupin"],
    ["To have been loved so deeply, even though the person who loved us is gone, will give us some protection forever.","Albus Dumbledore"],
    ["Alas! Ear wax!","Albus Dumbledore"],
    ["Amazing! Amazing! This is just like magic!","Gilderoy Lockhart"],
    ["We're coming with you. That was decided months ago — years, really.","Hermione Granger"],
    ["You shall not harm Harry Potter!","Dobby"],
    ["Such a beautiful place this is, to be with friends, Dobby feels joy for being with his friends, Harry Potter.","Dobby"]
];