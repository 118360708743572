import React, { useEffect, useState, useContext } from 'react';
import './features.css'
import '../../components/main/main.css'
import { quoteContext } from '../../contexts/QuoteContext';
import TextAnimation from '../../animations/TextAnimation';
import { quotes } from '../../assets/quotes'

function Features() {
  const [quote, setQuote] = useState(null);
  useEffect(() => {
      var num = quotes.length;
      num = Math.floor(Math.random() * quotes.length);
      var selectedQuote = quotes[num];
      setQuote(selectedQuote);
  },[]);

  const {value, setValue} = useContext(quoteContext)
  setValue(quote)

  return (
    <div className="content --centered">
        <p className="centered mb"><TextAnimation /></p>
    </div>
  )
}
export default Features