import React, { useEffect, useState } from 'react';
import './main.css';
import { bgImages } from '../../assets/images.js';

function Main() {
    const [bgImage, setImage] = useState(null);
    useEffect(() => {
        var num = bgImages.length;
        num = Math.floor(Math.random() * bgImages.length);
        var image = bgImages[num];
        setImage(image);
    },[]);
    return (
        <div className="content --centered">
            <div>
                <img alt="Hogwarts Castle" src={bgImage} id="homepage-background" />
                <div className='text-content'>
                    <h1 className="coming-soon centered mb">Portkey</h1>
                </div>
            </div>
        </div>
    );
}


export default Main;
