import React, { useState, useEffect } from "react";
import "./App.css";
import { Features, Footer, Search} from "./containers";
import { Main } from "./components";
import { quoteContext } from "./contexts/QuoteContext";
import GA4React from "react-ga4";
import ReactGA from 'react-ga';
ReactGA.initialize("UA-158927890-4");
ReactGA.pageview(window.location.pathname + window.location.search);
GA4React.initialize("G-FJ3KB28S4F");
GA4React.send("pageview");

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    GA4React.send("pageview");
  }, []);

  const [value, setValue] = useState("Issue getting quote");
  return (
    <div id="page-wrapper">
      <div id="page-content">
        <quoteContext.Provider value={{ value, setValue }}>
          <Main />
          <Search />
          <Features />
        </quoteContext.Provider>
      </div>
      
      <Footer />
    </div>
  );
}

export default App;
